import React from "react";

function CorporationImgContainer() {
    const corporationList = ["SM-Ministry", "SM-Business-center", "SKPlanet", "Sandburg"];

    return (
        <section className="w-screen flex justify-center gap-6 p-2.5 bg-white">
            {corporationList.map((e, i) => (
                <img
                    key={`corporation-img-${i}`}
                    src={`/assets/img/${e}.png`}
                    alt={e}
                    className="h-4 lg:h-6 object-contain"
                />
            ))}
        </section>
    );
}

export default CorporationImgContainer;
