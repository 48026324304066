import React from "react";

function PrivacyModal({ setModalState, setPrivacyChange }) {
    return (
        <div>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-[650px] max-w-3xl mx-auto">
                    <div className="relative flex flex-col p-2 w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                        <h1
                            id="mp-title"
                            className="m-title flex items-center py-4 pl-8 m-0 text-3xl font-semibold border-b"
                        >
                            개인정보 수집 및 이용 동의서
                        </h1>

                        <div id="mp-body" className="relative px-8 py-4">
                            <div className="flex flex-col gap-1">
                                <p className="m-0 text-[0.8rem]">
                                    주식회사 샌드버그는 개인정보 보호법 등 관련 법령에 따라 고객님의 개인정보를
                                    보호하고,
                                    <br />
                                    개인정보와 관련한 고객님의 권익을 보호하기 위하여 다음과 같이 개인정보를 수집 및
                                    이용하고자 합니다.
                                </p>
                                <p className="m-0 text-[0.8rem] pb-[0.9rem]">
                                    이에 동의하시면 아래 체크 박스에서 동의를 표시해주시기 바랍니다.
                                </p>
                            </div>
                            <div id="mp-disc" className="h-[300px] overflow-scroll">
                                <p className="m-0 text-[0.9rem] font-semibold text-black">1. 수집하는 개인정보 항목</p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 성명, 연락처(전화번호, 이메일 주소), 사업자명, 사업자 등록번호 등
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black pt-[0.625rem]">
                                    2. 개인정보의 수집 및 이용 목적
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 서비스 제공 및 계약 이행
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">• 고객 상담 및 민원 처리</p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">• 이용 기록 분석 및 통계</p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">• 마케팅 및 광고 활용</p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black pt-[0.625rem]">
                                    3. 개인정보의 보유 및 이용 기간
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 수집된 개인정보는 수집 및 이용 목적이 달성된 후 지체 없이 파기합니다.
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 단, 관련 법령에 따라 일정 기간 보관해야 하는 경우는 예외로 합니다.
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black pt-[0.625rem]">
                                    4. 개인정보 제공 및 공유
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 회사는 원칙적으로 고객님의 개인정보를 외부에 제공하지 않습니다.
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 다만, 고객님의 동의가 있거나 법령에 의한 경우는 예외로 합니다.
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black pt-[0.625rem]">
                                    5. 고객님의 권리
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 고객님은 언제든지 개인정보의 열람, 정정, 삭제, 처리 정지 요구를 할 수 있습니다.
                                </p>
                                <p className="pl-[0.625rem] text-[1rem] text-black/90 m-0">
                                    • 이러한 요청은 회사의 개인정보 보호책임자에게 서면, 전화, 이메일 등을 통해 하실 수
                                    있습니다.
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col items-start p-4 border-t">
                            <div>
                                <p className="m-0 font-semibold text-blue-700/80">[ 동의 여부 ]</p>
                                <p className="m-0 font-normal text-black">
                                    본인은 위의 내용을 충분히 이해하였으며, 개인정보를 제공하는 것에 대해 동의합니다.
                                </p>
                            </div>

                            <div className="flex justify-end w-full gap-2 pt-3">
                                <button
                                    className="font-bold text-red-500 duration-200 text-[0.875rem] rounded py-1 px-3 hover:bg-slate-100"
                                    type="button"
                                    onClick={setModalState}
                                >
                                    동의하지 않음
                                </button>
                                <button
                                    className="text-white duration-200 rounded bg-emerald-500 hover:bg-emerald-600 font-bold text-[0.875rem] px-3 py-1"
                                    type="button"
                                    onClick={setPrivacyChange}
                                >
                                    동의함
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </div>
    );
}

export default PrivacyModal;
