import React from "react";

function SubmitModal({ linkURL, setModalState }) {
    return (
        <div>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-[650px] max-w-3xl mx-auto">
                    <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                        <h1
                            id="ms-title"
                            className="flex items-center py-4 pl-[28px] m-0 text-3xl font-semibold border-b"
                        >
                            신청이 완료되었습니다!
                        </h1>

                        <div className="relative px-[32px] pt-4 pb-3">
                            <div className="flex flex-col items-center">
                                <div id="ms-body" className="flex items-center gap-1">
                                    <p className="m-0 text-[0.95rem] font-semibold text-black">
                                        판판대로 [ 전담셀러 매칭지원사업 ]
                                    </p>
                                    <p className="m-0 text-[0.95rem] font-semibold text-black">
                                        을 신청해주셔서 감사합니다!
                                    </p>
                                </div>
                                <p className="m-0 text-[0.95rem] font-semibold text-black">
                                    이후 SK 플래닛에서 연락드릴 예정입니다 😊
                                </p>

                                <p className="pt-4 m-0 text-[0.95rem] text-center">
                                    아래 버튼을 통해 <br className="lg:hidden" />
                                    작성된 신청서를 다운 받으실 수 있습니다.
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center w-full gap-2 px-4 pt-0 pb-4">
                            <a
                                href={linkURL}
                                onClick={() => setModalState(false)}
                                id="file"
                                className="text-white py-2.5 w-full text-center cursor-pointer duration-200 rounded bg-emerald-500 hover:bg-emerald-600 font-bold text-[16px] px-3"
                            >
                                서류 다운로드
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </div>
    );
}

export default SubmitModal;
