import "./App.css";
import "./css/main.css";
import "./css/util.css";
import "./fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "./fonts/iconic/css/material-design-iconic-font.min.css";
// import "./vendor/bootstrap/css/bootstrap.min.css";
import "./vendor/css-hamburgers/hamburgers.min.css";
import "./vendor/animsition/css/animsition.min.css";
import "./vendor/select2/select2.min.css";
import "./vendor/daterangepicker/daterangepicker.css";
import { useState, useEffect } from "react";
import axios from "axios";
import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "./loading";
import PrivacyModal from "./components/privacy-modal";
import ThirdPartyModal from "./components/third-party-modal";
import SubmitModal from "./components/submit-modal";
import CorporationImgContainer from "./components/corporation-img-container";
import ReactPlayer from "react-player";
import { useScroll } from "./hooks/use-auto-scroll";

function App() {
    const SERVER_API = process.env.REACT_APP_SERVER_API;

    // contact number format
    const [contactNum, setContactNum] = useState("");
    const { targetRef, scrollToTarget } = useScroll();

    const formatContactNum = (num) => {
        num = num.replace(/[^0-9]/g, "");

        if (num.length <= 3) {
            num = num.replace(/(\d{3})/, "$1");
        } else if (num.length <= 7) {
            num = num.replace(/(\d{3})(\d{1,4})/, "$1-$2");
        } else if (num.length <= 11) {
            num = num.replace(/(\d{3})(\d{4})(\d{1,4})/, "$1-$2-$3");
        }
        return setContactNum(num);
    };

    // bisNumber format
    const [bisNum, setBisNum] = useState("");

    const formatBisNumber = (num) => {
        num = num.replace(/[^0-9]/g, "");

        if (num.startsWith("0")) {
            return;
        }

        if (num.length <= 3) {
            num = num.replace(/(\d{3})/, "$1");
        } else if (num.length <= 5) {
            num = num.replace(/(\d{3})(\d{1,2})/, "$1-$2");
        } else if (num.length <= 10) {
            num = num.replace(/(\d{3})(\d{2})(\d{1,5})/, "$1-$2-$3");
        }
        return setBisNum(num);
    };

    // privacy modal: 개인정보 모달, third party modal: 제3자 모달
    const [showPrivacyModal, setShowPrivacyModal] = useState(false);
    const [showThirdPartyModal, setShowThirdPartyModal] = useState(false);
    const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
    const [isThirdPartyChecked, setIsThirdPartyChecked] = useState(false);

    // 모달 노출
    const handlePrivacyCheckboxChange = (e) => {
        if (isPrivacyChecked) {
            setIsPrivacyChecked(false) && setShowPrivacyModal(false);
            return;
        }
        setShowPrivacyModal(true);
    };
    const handleThirdPartyCheckboxChange = (e) => {
        if (isThirdPartyChecked) {
            setIsThirdPartyChecked(false) && setShowThirdPartyModal(false);
            return;
        }
        setShowThirdPartyModal(true);
    };

    // 모달 동의
    const handleSavePrivacyChanges = () => {
        setIsPrivacyChecked(true);
        setShowPrivacyModal(false);
    };
    const handleSaveThirdPartyChanges = () => {
        setIsThirdPartyChecked(true);
        setShowThirdPartyModal(false);
    };

    // 모달 미동의
    const handleClosePrivacyModal = () => {
        setIsPrivacyChecked(false);
        setShowPrivacyModal(false);
    };
    const handleCloseThirdPartyModal = () => {
        setIsThirdPartyChecked(false);
        setShowThirdPartyModal(false);
    };

    const [userData, setUserData] = useState({
        name: "",
        bisName: "",
        bisNumber: "",
        contact: null,
        notHaveOwnMall: null,
        reportBuisness: null,
        agreeToMarketing: null,
    });

    // setUserData
    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;

        setUserData((prevState) => ({
            ...prevState,
            [name]: type === "checkbox" ? checked : value,
        }));
    };
    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    // active submit button
    const isButtonDisabled = !(isPrivacyChecked && isThirdPartyChecked);

    const isDataFull = Object.values(userData).every((value) => value !== "" && value !== null);

    // input error handling
    const [bisNumError, setBisNumError] = useState(false);
    const [contactNumError, setContactNumError] = useState(false);

    useEffect(() => {
        const bisInput = document.getElementById("bisNumber");
        const bisLabel = document.getElementById("bisNumLabel");
        const contactInput = document.getElementById("contact");
        const contactLabel = document.getElementById("contactLabel");

        if (bisInput && bisLabel && contactInput && contactLabel) {
            if (bisNumError) {
                bisInput.focus();
                bisLabel.classList.add("text-red-400");
                bisInput.classList.add("focus:ring-red-400");
            } else {
                bisLabel.classList.remove("text-red-400");
                bisInput.classList.remove("focus:ring-red-400");
            }

            if (contactNumError) {
                contactInput.focus();
                contactLabel.classList.add("text-red-400");
                contactInput.classList.add("focus:ring-red-400");
            } else {
                contactLabel.classList.remove("text-red-400");
                contactInput.classList.remove("focus:ring-red-400");
            }
        }
    }, [bisNumError, contactNumError]);

    // submit
    const [isLoading, setIsLoading] = useState(false);
    const [isDownloaded, setIsDownloaded] = useState(false);
    const [linkURL, setLinkURL] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isPrivacyChecked || !isThirdPartyChecked) {
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post(
                `https://${SERVER_API}/api/sk/apply`,
                {
                    ...userData,
                    notHaveOwnMall: +userData.notHaveOwnMall,
                    reportBuisness: +userData.reportBuisness,
                    agreeToMarketing: +userData.agreeToMarketing,
                },
                { headers: { "Content-Type": "application/json" } }
            );

            setIsLoading(false);
            setIsDownloaded(true);
            setLinkURL(response.data);
        } catch (error) {
            setIsLoading(false);
            const errorData = error.response.data;

            if (errorData.statusCode === 409) {
                setBisNumError(true);
                setContactNumError(false);
                toast.error("이미 신청된 사업자입니다.");
            } else if (errorData.statusCode === 400) {
                if (errorData.message.bisNumber) {
                    setBisNumError(true);
                    setContactNumError(false);
                    toast.error("사업자번호를 정확하게 입력해주세요.");
                } else if (errorData.message.contact) {
                    setContactNumError(true);
                    setBisNumError(false);
                    toast.error("정확한 연락처를 입력해주세요.");
                }
            } else {
                alert(errorData.message);
                setBisNumError(false);
                setContactNumError(false);
            }
        }
    };

    return (
        <main className="flex-col lg:px-[4%] w-full gap-10 items-center bg-gradient-to-t from-[#eeeeee] from-70% to-[#79ACFF2A] to-100%">
            <CorporationImgContainer />

            <div className="flex-col text-strong font-one-bold items-center">
                <span className="text-[1.3rem] lg:text-[1.5rem]">쉬운 줄 알았지만 할일 많았던 이커머스,</span>
                <span className="text-[1.75rem] lg:text-[2.25rem] font-one-title">전담 셀러가 모든 걸 지원합니다</span>
            </div>
            <img src="/assets/img/main.png" alt="introduce-main-img" className="w-[24rem] h-[21rem]" />
            <div className="flex-col text-strong font-one-bold items-center">
                <span className="text-[1.3rem] lg:text-[1.5rem] text-center">
                    번거로운 쇼핑몰 관리 상품등록, <br className="lg:hidden" />
                    마케팅, 정산까지 한 번에 지원
                </span>
                <span className="text-[1.75rem] lg:text-[2.25rem] font-one-title text-center">
                    영업은 전담셀러가,
                    <br className="lg:hidden" /> 사장님은 상품만!
                </span>
            </div>

            {/* 개인정보 모달 & 다운로드 모달 렌더링 */}
            {showPrivacyModal && (
                <PrivacyModal setModalState={handleClosePrivacyModal} setPrivacyChange={handleSavePrivacyChanges} />
            )}
            {showThirdPartyModal && (
                <ThirdPartyModal
                    setModalState={handleCloseThirdPartyModal}
                    setThirdPartyChange={handleSaveThirdPartyChanges}
                />
            )}
            {isDownloaded && <SubmitModal setModalState={setIsDownloaded} linkURL={linkURL} />}

            <div className="w-full max-w-[960px] flex-col gap-8 py-9 px-6 lg:px-9 bg-white rounded-lg relative">
                <section className="flex gap-6 items-center">
                    <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                    <span className="font-one-title text-[1.25rem] text-primary">지원규모</span>
                    <span className="font-one-regular text-[1.25rem]">300개사</span>
                </section>

                <section className="flex gap-6 items-start">
                    <div className="flex gap-6 items-center">
                        <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                        <span className="font-one-title text-[1.25rem] text-primary">신청기간</span>
                    </div>
                    <div className="flex-col gap-1">
                        <span className="font-one-regular text-[1.25rem]">~09월 30일 까지</span>
                        <span className="font-one-light text-primary text-[0.875rem]">*지원 목표 달성시 조기 종료</span>
                    </div>
                </section>

                <section className="flex gap-6 items-center">
                    <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                    <span className="font-one-title text-[1.25rem] text-primary">지원내용</span>
                    <span className="font-one-title text-[1.3rem] lg:text-[1.5rem] text-secondary">
                        2,105,217원 (100% 국비지원)
                    </span>
                </section>

                <section className="flex gap-6 items-center">
                    <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                    <span className="font-one-title text-[1.25rem] text-primary">지원채널</span>
                    <span className="font-one-regular text-[1.25rem]">
                        네이버스마트스토어, 11번가, G마켓 등 온라인 쇼핑몰
                    </span>
                </section>

                <section className="flex gap-6 items-start pb-4">
                    <div className="flex gap-6 items-center">
                        <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                        <span className="font-one-title text-[1.25rem] text-primary">신청방법</span>
                    </div>
                    <div className="flex-col gap-4">
                        <span className="font-one-title text-[1.3rem] lg:text-[1.5rem] text-secondary">
                            하단 입력 폼 작성
                        </span>
                        <button
                            className="flex items-center font-one-bold text-[0.75rem] text-white bg-primary px-5 py-3 rounded-full duration-300 active:duration-75 active:scale-[.99] hover:scale-[1.02] hover:bg-primary-hover"
                            onClick={scrollToTarget}
                        >
                            입력 폼 바로가기 Click!
                            <img src="/assets/icon/arrow-right-curved.png" alt="arrow-right-curved" />
                        </button>
                    </div>
                </section>
                <section className="flex-col gap-10">
                    <div className="flex gap-6 items-center">
                        <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                        <span className="font-one-title text-[1.25rem] text-primary">전담 셀러 매칭 지원 내용</span>
                    </div>

                    <div className="flex gap-[0.825rem]">
                        <img src="/assets/icon/check.png" alt="check.png" className="w-7 h-7 mt-2" />
                        <div className="flex-col gap-1">
                            <span className="font-one-title text-[1.25rem]">콘텐츠 제작 및 가공(필요시):</span>
                            <span className="font-one-light text-[1.25rem]">
                                이미지 또는 상세페이지가 없거나 부족한 경우 제작 및 개선
                            </span>
                        </div>
                    </div>

                    <div className="flex gap-[0.825rem]">
                        <img src="/assets/icon/check.png" alt="check.png" className="w-7 h-7 mt-2" />
                        <div className="flex-col gap-1">
                            <span className="font-one-title text-[1.25rem]">진단 및 컨설팅:</span>
                            <span className="font-one-light text-[1.25rem]">
                                상품에 대한 가격설정, 판매채널 및 콘텐츠 등에 대한 컨설팅
                            </span>
                        </div>
                    </div>

                    <div className="flex gap-[0.825rem]">
                        <img src="/assets/icon/check.png" alt="check.png" className="w-7 h-7 mt-2" />
                        <div className="flex-col gap-1">
                            <span className="font-one-title text-[1.25rem]">판매대행:</span>
                            <span className="font-one-light text-[1.25rem]">
                                쇼핑몰 입점, 정산, 판매관리, 마케팅업무, CS까지 판매 전 과정 지원
                            </span>
                        </div>
                    </div>
                </section>

                <section className="flex-col gap-[0.825rem]">
                    <div className="flex gap-6 items-center">
                        <img src="/assets/icon/ellipse.png" alt="ellipse.png" className="w-3 h-3" />
                        <span className="font-one-title text-[1.25rem] text-primary">진행절차</span>
                    </div>
                    <div className="grid grid-cols-7 place-items-center lg:place-items-stretch lg:flex gap-3 lg:gap-5">
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/form-empty.png"
                                alt="form-empty"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <span className="font-one-regular text-[0.825rem] text-center">
                                입력 폼<br /> 작성
                            </span>
                        </div>
                        <img
                            src="/assets/icon/arrow-right-full.png"
                            alt="arrow-right-full"
                            className="w-5 h-5 lg:mt-10"
                        />
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/phone.png"
                                alt="phone"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <div className="flex-col items-center">
                                <span className="font-one-regular text-[0.6rem] lg:text-[0.825rem] text-center">
                                    (주)SK플래닛
                                    <br /> 전담셀러매칭
                                </span>
                                <span className="font-one-light text-[0.5rem] lg:text-[0.6rem] text-center">
                                    (유선 통화 예정)
                                </span>
                            </div>
                        </div>
                        <img
                            src="/assets/icon/arrow-right-full.png"
                            alt="arrow-right-full"
                            className="w-5 h-5 lg:mt-10"
                        />
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/form-full.png"
                                alt="form-full"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <span className="font-one-regular text-[0.825rem] text-center">진단 및 컨설팅</span>
                        </div>
                        <img
                            src="/assets/icon/arrow-right-full.png"
                            alt="arrow-right-full"
                            className="w-5 h-5 lg:mt-10"
                        />
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/box-opened.png"
                                alt="box-opened"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <span className="font-one-regular text-[0.825rem] text-center">상품 등록</span>
                        </div>
                        <img
                            src="/assets/icon/arrow-right-full.png"
                            alt="arrow-right-full"
                            className="opacity-0 lg:opacity-100 w-5 h-5 lg:mt-10"
                        />
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/shopping-cart.png"
                                alt="shopping-cart"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <span className="font-one-regular text-[0.825rem] text-center">
                                마케팅 및<br /> 판매활동
                            </span>
                        </div>
                        <img
                            src="/assets/icon/arrow-right-full.png"
                            alt="arrow-right-full"
                            className="w-5 h-5 lg:mt-10"
                        />
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/receipt-checked.png"
                                alt="receipt-checked"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <span className="font-one-regular text-[0.825rem] text-center">정산</span>
                        </div>
                        <img
                            src="/assets/icon/arrow-right-full.png"
                            alt="arrow-right-full"
                            className="w-5 h-5 lg:mt-10"
                        />
                        <div className="flex-col gap-2.5 items-center">
                            <img
                                src="/assets/icon/headphone-message.png"
                                alt="headphone-message"
                                className="h-16 object-contain cursor-pointer lg:h-20"
                                onClick={scrollToTarget}
                            />
                            <span className="font-one-regular text-[0.825rem] text-center">CS</span>
                        </div>
                    </div>
                    <span className="font-one-light text-[0.825rem] text-primary mt-5">
                        *이미지 클릭 시 입력 폼 화면으로 이동됩니다.
                    </span>
                </section>

                <section className="w-full flex-col gap-6 items-center relative">
                    <span className="font-one-title text-[1.6rem] lg:text-[2rem]">
                        <strong className="text-primary font-medium">자기부담금 없이, 전액 지원</strong>
                        되는 사업을 신청하세요!
                    </span>
                    <div className="w-full lg:w-[50rem] aspect-[16/9] relative">
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=8U2AaH_qxZU"
                            width="100%"
                            height="100%"
                            playing
                        />
                    </div>
                    <span className="font-one-regular text-[1rem] lg:text-[1.25rem] text-center">
                        중소벤처기업부와 (주)SK플래닛 그리고 (주)샌드버그와 함께!
                        <br /> ‘판판대로 전담셀러 매칭지원사업'은 대표님의 상품을 소개하는 상세페이지 제작과, <br />
                        판매 전 과정을 지원하는 중소벤처기업부의 지원사업입니다.
                    </span>
                </section>

                <hr />
                <span className="font-one-title text-[1.25em]" ref={targetRef}>
                    전담셀러 매칭 지원사업 신청을 원하시면
                    <br />
                    <strong className="text-secondary font-medium">아래의 입력 폼을 작성해주세요!</strong>
                </span>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div className="space-y-4">
                        <div className="relative">
                            <label className="block mb-1 text-sm font-medium">대표자명</label>

                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:border-secondary"
                                type="text"
                                name="name"
                                placeholder="대표자명을 입력해주세요."
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="relative">
                            <label className="block mb-1 text-sm font-medium">사업자명</label>

                            <input
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:border-secondary"
                                type="text"
                                name="bisName"
                                placeholder="상호명을 입력해주세요"
                                onChange={handleInputChange}
                            />
                        </div>

                        <div className="relative">
                            <label id="bisNumLabel" className="block mb-1 text-sm font-medium">
                                사업자등록번호
                            </label>

                            <div>
                                <ToastContainer
                                    position="bottom-center"
                                    autoClose={3000}
                                    hideProgressBar={false}
                                    closeButton={false}
                                    pauseOnHover={false}
                                    pauseOnFocusLoss
                                    closeOnClick
                                    draggable
                                    icon
                                />
                            </div>

                            <input
                                id="bisNumber"
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:border-secondary"
                                maxLength={12}
                                type="text"
                                name="bisNumber"
                                placeholder="123-45-67890"
                                onInput={(e) => {
                                    formatBisNumber(e.target.value);
                                }}
                                onChange={handleInputChange}
                                value={bisNum}
                            />
                        </div>

                        <div className="relative">
                            <label id="contactLabel" className="block mb-1 text-sm font-medium">
                                연락처
                            </label>

                            <input
                                id="contact"
                                className="w-full px-3 py-2 border border-gray-300 rounded focus:border-secondary"
                                maxLength={13}
                                type="text"
                                name="contact"
                                placeholder="010-1234-5678"
                                onInput={(e) => {
                                    formatContactNum(e.target.value);
                                }}
                                onChange={handleInputChange}
                                value={contactNum}
                            />
                        </div>

                        <div className="h-[20px] border-b border-gray-300" />

                        <div className="relative">
                            <label className="block mb-1 text-sm font-medium">자사몰 보유 여부</label>
                            <div className="flex gap-4">
                                <label className="flex items-center  text-[0.875rem]">
                                    <input
                                        className="mr-2"
                                        type="radio"
                                        name="notHaveOwnMall"
                                        value="0"
                                        onChange={handleRadioChange}
                                    />
                                    예
                                </label>
                                <label className="flex items-center text-[0.875rem]">
                                    <input
                                        className="mr-2"
                                        type="radio"
                                        name="notHaveOwnMall"
                                        value="1"
                                        onChange={handleRadioChange}
                                    />
                                    아니오
                                </label>
                            </div>
                        </div>

                        <div className="relative">
                            <label className="block mb-1 text-sm font-medium">통신판매업 신고</label>
                            <div id="report" className="flex gap-4">
                                <label className="flex items-center  text-[0.875rem]">
                                    <input
                                        className="mr-2"
                                        type="radio"
                                        name="reportBuisness"
                                        value="1"
                                        onChange={handleRadioChange}
                                    />
                                    통신판매업 미신고
                                </label>
                                <label className="flex items-center  text-[0.875rem]">
                                    <input
                                        className="mr-2"
                                        type="radio"
                                        name="reportBuisness"
                                        value="2"
                                        onChange={handleRadioChange}
                                    />
                                    24년 1월 이후 신고
                                </label>
                                <label className="flex items-center  text-[0.875rem]">
                                    <input
                                        className="mr-2"
                                        type="radio"
                                        name="reportBuisness"
                                        value="0"
                                        onChange={handleRadioChange}
                                    />
                                    해당없음
                                </label>
                            </div>
                        </div>

                        <div className="relative">
                            <label className="block mb-1 text-sm font-medium">신청내용 사실 확인 서약서</label>
                            <p className="m-0 text-[0.875rem]">
                                위 가점 허위 신청 시, <br /> 지원 중지 및 향후 지원 사업 지원 불가 조치에 대해
                                동의합니다.
                            </p>
                            <div className="flex gap-4">
                                <label className="flex items-center  text-[0.875rem]">
                                    <input
                                        className="mr-2"
                                        type="radio"
                                        name="agreeToMarketing"
                                        value="1"
                                        onChange={handleRadioChange}
                                    />
                                    예
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="mt-4 space-y-2">
                        <div className="flex items-center">
                            <input
                                className="w-5 h-5 cursor-pointer text-blue-600 border-gray-300 rounded"
                                type="checkbox"
                                id="CheckBox1"
                                checked={isPrivacyChecked}
                                onChange={handlePrivacyCheckboxChange}
                            />
                            <label className="mb-0 ml-2 text-sm" htmlFor="CheckBox1">
                                개인정보 수집 동의
                            </label>
                        </div>

                        <div className="flex items-center">
                            <input
                                className="w-5 h-5 cursor-pointer text-blue-600 border-gray-300 rounded"
                                type="checkbox"
                                id="CheckBox2"
                                checked={isThirdPartyChecked}
                                onChange={handleThirdPartyCheckboxChange}
                            />
                            <label className="mb-0 ml-2 text-sm" htmlFor="CheckBox2">
                                제 3자 개인정보제공 동의
                            </label>
                        </div>
                    </div>

                    <div className="mt-6">
                        {isLoading ? (
                            <button
                                type="submit"
                                disabled
                                className="w-full py-3 text-white text-center font-semibold transition rounded hover:bg-[#F36A5E] bg-[#EB465A] duration-200"
                            >
                                <Loading />
                            </button>
                        ) : (
                            <button
                                type="submit"
                                disabled={isButtonDisabled}
                                className={`w-full py-3 text-white text-center font-semibold transition rounded ${
                                    isPrivacyChecked && isThirdPartyChecked && isDataFull
                                        ? "filter hover:bg-primary-hover bg-primary duration-200"
                                        : "bg-slate-300"
                                }`}
                            >
                                제출하기
                            </button>
                        )}
                    </div>
                </form>
            </div>
            <section className="flex-col gap-3 items-center">
                <span className="font-one-bold text-black-light text-[0.825rem]">
                    문의처: (주) 샌드버그 (051-711-4488 / contact@sandburg.co.kr)
                </span>
                <CorporationImgContainer />
            </section>
        </main>
    );
}

export default App;
