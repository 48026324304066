import React from "react";

const ThirdPartyModal = ({ setModalState, setThirdPartyChange }) => {
    return (
        <div>
            <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                <div className="relative w-[650px] max-w-3xl mx-auto">
                    <div className="relative flex flex-col w-full p-2 bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                        <h1
                            id="mt-title"
                            className="flex items-center py-4 pl-[2rem] m-0 text-3xl font-semibold border-b"
                        >
                            제3자 개인정보 제공 동의서
                        </h1>

                        <div id="mt-body" className="relative px-[2rem] py-4 overflow-y-scroll h-[500px]">
                            <div className="flex flex-col gap-1">
                                <p className="m-0 text-[0.9rem] font-semibold text-black">1. 개인정보 제공받는 자</p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0">
                                    • 회사명: (주)에스케이플래닛
                                </p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0">
                                    • 주소: 경기 성남시 분당구 판교로 264 더플래닛
                                </p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0 pb-[0.9rem]">
                                    • 연락처: 02-6119-0114
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black">2. 제공하는 개인정보 항목</p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0 pb-[0.9rem]">
                                    • 제공할 개인정보 항목: 이름, 연락처, 사업자명(상호명), 사업자등록번호 등
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black">3. 개인정보 제공 목적</p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0 pb-[0.9rem]">
                                    • 제공하는 목적: 판판대로 사업 소개 및 서비스 제공, 고객 관리, 마케팅 활용 등
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black">
                                    4. 개인정보 보유 및 이용 기간
                                </p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0">
                                    • 수집된 개인정보는 수집 및 이용 목적이 달성된 후 지체 없이 파기합니다.
                                </p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0 pb-[0.9rem]">
                                    • 단, 관련 법령에 따라 일정 기간 보관해야 하는 경우는 예외로 합니다.
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black">
                                    5. 동의를 거부할 권리 및 동의 거부 시 불이익 내용
                                </p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0 pb-[0.9rem]">
                                    • 귀하는 개인정보 제공에 대한 동의를 거부할 권리가 있습니다. 다만, 동의를 거부할
                                    경우 서비스 제공에 제한이 있을 수 있습니다.
                                </p>

                                <p className="m-0 text-[0.9rem] font-semibold text-black">6. 기타 사항</p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0">
                                    • 기타 개인정보 제공과 관련한 사항은 개인정보 보호책임자에게 문의해주시기 바랍니다.
                                </p>
                                <p className="pl-[0.625rem] text-black/90 text-[1rem] m-0 pb-[0.9rem]">
                                    • 연락처: 010-2413-4498
                                </p>
                            </div>
                        </div>

                        <div className="flex flex-col items-start p-4 border-t">
                            <div>
                                <p className="m-0 font-semibold text-blue-700/80">[ 동의 여부 ]</p>
                                <p className="m-0 font-normal text-black">
                                    본인은 위의 내용을 충분히 이해하였으며, 제3자에게 개인정보를 제공하는 것에 대해
                                    동의합니다.
                                </p>
                            </div>

                            <div className="flex justify-end w-full gap-2 pt-3">
                                <button
                                    className="font-bold text-red-500 duration-200 text-[0.875rem] rounded py-1 px-3 hover:bg-slate-100"
                                    type="button"
                                    onClick={setModalState}
                                >
                                    동의하지 않음
                                </button>
                                <button
                                    className="text-white duration-200 rounded bg-emerald-500 hover:bg-emerald-600 font-bold text-[0.875rem] px-3 py-1"
                                    type="button"
                                    onClick={setThirdPartyChange}
                                >
                                    동의함
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
        </div>
    );
};

export default ThirdPartyModal;
